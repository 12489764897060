import { SectionTitleLeft } from '../SectionTitles';

const Services = () => {
	return (
		<section
			id="ostéopathie"
			className="servicesWrap py-[4.5rem] md:pt-[5.5rem] lg:pt-7 xl:pt-20 pb-[4.5rem] md:pb-[5.5rem] lg:pb-[6.25rem] relative w-full"
		>
			<div className="container sm:container md:container lg:container xl:max-w-[73.125rem] mx-auto">
				<div className="servDetail relative w-full">
					<SectionTitleLeft
						title="L'Ostéopathie"
						titleInner=""
						desc="L’ostéopathie est une approche thérapeutique manuelle douce qui s'emploie à déterminer et à traiter les restrictions de mobilité qui peuvent affecter l'ensemble des structures composant le corps humain."
					></SectionTitleLeft>
					<div className="servDetailDesc flex flex-col gap-6 w-full">
						<p className="text-desc2 text-[1rem] md:text-[1.25rem] sm:text-[1.225rem] mt-1">
							En cas de non contre-indication, l'ostéopathe peut prendre en
							charges tous troubles fonctionnels et douleurs de l'ensemble du
							corps (articulations, muscles, tête, mâchoire, thorax, abdomen,
							sphère uro-gynéco, etc.).{' '}
						</p>
						<p className="text-desc2 text-[1rem] md:text-[1.25rem] sm:text-[1.225rem] mt-1">
							Les ostéopathes sont formés pour exclure des pathologies
							nécessitants des examens et/ou une prise en charge médicale. Ils
							adapteront le traitement à votre motif de consultation, vos
							antécédents et vos douleurs.
						</p>
						<p className="text-desc2 text-[1rem] md:text-[1.25rem] sm:text-[1.225rem] mt-1">
							L'ostéopathie est fondée sur la capacité du corps à
							s'autoéquilibrer et sur une connaissance approfondie de l'anatomie
							et la physiologie.
						</p>
						<p className="text-desc2 text-[1rem] md:text-[1.25rem] sm:text-[1.225rem] mt-1">
							Elle nécessite des compétences spécifiques, une connaissance
							approfondie du fonctionnement du corps humain et des intéractions
							entre chacun de ses systèmes.
						</p>
					</div>
					<div className="servDetailDesc flex flex-col gap-6 w-full mt-3">
						<SectionTitleLeft
							title="Pour qui ?"
							titleInner=""
							desc="	Je prend en charge tous types de patients : femmes enceintes,
							nourrissons, enfants, adolescents, adultes, seniors, sportifs de
							tous niveaux."
						></SectionTitleLeft>
					</div>
					{/* Service Detail */}
				</div>
			</div>
			{/* Services Wrap */}
		</section>
	);
};

export default Services;
