import { Link } from 'react-router-dom';
import PatternBg from '../../assets/images/patternImg2.jpg';
import { priceTable } from '../../data/price-table';
import { SectionTitle3 } from '../SectionTitles';

const PriceTable = () => {
	return (
		<section
			id="honoraires"
			className="priceTableWrap pb-12 pt-[4.5rem] sm:pt-[5.5rem] md:pt-[6.25rem] relative w-full"
		>
			<div className="container bg-a sm:container md:container lg:container xl:max-w-[73.125rem] mx-auto">
       
				<div
					className="top-[-15.625rem]  before:absolute before:z-[1] before:inset-0 before:opacity-[.85] before:bg-white bg-contain bg-no-repeat  overflow-hidden inset-0 z-[-2] absolute"
					style={{ backgroundImage: `url(${PatternBg})` }}
				>
					{' '}
				</div>
				<SectionTitle3 title="Honoraires" titleInner=""></SectionTitle3>
				<div className="servDetailDesc flex flex-col gap-6 w-full mb-12">
					<h2 className="text-accent2 text-[1.875rem] md:text-[2.5rem] xl:text-[3.125rem] font-Poppins font-bold relative">
						<span className="text-accent text-[1.875rem] md:text-[2.5rem] xl:text-[3.125rem] font-Caveat font-bold relative">
							Moyens de paiement : CB, chèques, espèces
						</span>
					</h2>
					<p className="text-desc2 text-[1rem] md:text-[1.25rem] sm:text-[1.225rem] mt-1">
						Les consultations ostéopathiques sont de plus en plus remboursées
						par les mutuelles. Pour connaître les remboursements par votre
						mutuelle{' '}
						<Link href="https://www.osteopathie.org/mutuelles.html">
							<span className="text-shapBgDark">
								cliquez ici : https://www.osteopathie.org
							</span>
						</Link>
					</p>

					<p className="text-desc2 text-[1rem] md:text-[1.25rem] sm:text-[1.225rem] mt-1">
						L'ostéopathie n'est pas prise en charge par la sécurité sociale ou
						la CMU.
					</p>
				</div>
				<div className="priceTableBoxes grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12 xl:gap-[3.75rem] relative items-start w-100">
					{priceTable.map(
						(item, index) =>
							index < 3 && (
								<div
									className={`${item.pacBox} relative rounded-[10px] sm:rounded-[1.25rem] md:rounded-[1.875rem] lg:rounded-[3.125rem] overflow-hidden drop-shadow-2xl`}
									key={index}
								>
									<div
										className={`before:absolute before:z-[1] before:inset-0 before:opacity-40 bg-blend-multiply w-full ${item.pacBoxHead} py-[2.1875rem] px-[2.5rem] xl:px-[3.75rem] min-h-[9rem] xl:min-h-[11.875rem] flex justify-start items-end bg-cover before:rounded-t-[10px] before:sm:rounded-t-[1.25rem] before:md:rounded-t-[3.125rem] rounded-t-[10px] sm:rounded-t-[1.25rem] md:rounded-t-[3.125rem]`}
										style={{ backgroundImage: `url(${PatternBg})` }}
									>
										<h3
											className={`${item.pacTitleColor} text-[1.75rem] md:text-[2.75rem] uppercase font-bold font-Poppins relative z-[1]`}
										>
											{item.pacTitle}
										</h3>
									</div>
									<div
										className={`before:absolute before:z-[1] before:inset-0 before:opacity-40 bg-blend-multiply w-full ${item.pacBoxInner} flex flex-col bg-cover relative before:rounded-b-[10px] before:sm:rounded-b-[1.25rem] before:md:rounded-b-[3.125rem] rounded-b-[10px] sm:rounded-b-[1.25rem] md:rounded-b-[1.875rem] lg:rounded-b-[3.125rem]`}
										style={{ backgroundImage: `url(${PatternBg})` }}
									>
										<h3
											className={`${item.pacPriceColor} relative z-[1] font-Poppins font-semibold text-[2.5rem] xl:text-[3.125rem] leading-none`}
										>
											{item.pacPrice}
											<sup
												className={`${item.pacCurrencyColor} font-Poppins font-semibold text-[1.5rem] md:text-[2rem] leading-none relative -top-3`}
											>
												{item.pacCurrency}
											</sup>
										</h3>
									</div>
								</div>
							),
					)}
					{/* Price Table Boxes */}
				</div>
			</div>

			{/* Price Table Wrap */}
		</section>
	);
};

export default PriceTable;
