import { BrowserRouter, Route, Routes } from 'react-router-dom';
import SmoothScroll from '../components/used/SmoothScroll';
import { ErrorPage } from '../pages/ErrorPage';
import { HomePage2 } from '../pages/HomePages';

const RouterLinks = () => {
	return (
		<>
			<BrowserRouter>
				<SmoothScroll>
					<Routes>
						<Route path="/" element={<HomePage2></HomePage2>} />
						<Route path="/404" element={<ErrorPage></ErrorPage>} />
						<Route path="*" element={<ErrorPage></ErrorPage>} />
					</Routes>
				</SmoothScroll>
			</BrowserRouter>
		</>
	);
};

export default RouterLinks;
