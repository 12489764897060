import { Link } from 'react-router-dom';
import PatternImg2 from '../../assets/images/patternImg2.jpg';

const FreelanceWork2 = () => {
	return (
		<section className="freelanceWorkWrap w-full relative z-[1]">
			<div className="container sm:container md:container lg:container xl:container 2xl:container mx-auto">
				<div className="freelanceWork p-[1.875rem] sm:p-[2.5rem] md:pt-[4.0625rem] md:pb-[3.4375rem] md:pr-[3.75rem] xl:pr-[10.625rem] relative w-full">
					<div
						className="before:absolute before:inset-0 before:bg-accent before:rounded-[10px] before:sm:rounded-[1.25rem] before:md:rounded-r-[3.125rem] before:opacity-70 before:z-[1] bg-blend-multiply absolute rounded-[10px] sm:rounded-[1.25rem] md:rounded-r-[3.125rem] inset-0 md:left-auto w-full md:w-screen bg-no-repeat bg-center bg-cover z-[-1] bg-accent"
						style={{ backgroundImage: `url(${PatternImg2})` }}
					></div>
					<div className="grid gap-[1.875rem] md:grid-cols-2 grid-cols-1 text-center md:text- items-center">
						<div className="md:order-1">
							<div className="freelanceImg w-full">
								<Link
									className="bg-white border-2 border-current text-accent text-[1rem] font-Poppins font-bold uppercase rounded-[5px] md:rounded-[10px] md:px-6 lg:px-10 xl:px-11 px-7 md:py-[1rem] py-[10px] hover:bg-accent hover:border-accent hover:text-white text-center inline-block"
									to="https://www.doctolib.fr/osteopathe/gagny/timothe-hacquemand"
									title="Prendre rendez-vous"
								>
									Prendre rendez-vous
								</Link>
							</div>
						</div>
						<div>
							<div className="freelanceInfo w-full">
								<h2 className="text-white font-Poppins font-bold text-[1.4rem] sm:text-[2rem] md:text-[2rem] lg:text-[2.55rem]">
									Je suis disponible !{' '}
								</h2>
								<h5 className="text-white font-Poppins font-semibold text-[1rem] sm:text-[1.125rem] md:text-[1.125rem] lg:text-[1.25rem]">
									Timothé Hacquemand - Ostéophate D.O
								</h5>

								{/* Freelance Info */}
							</div>
						</div>
					</div>
					{/* Freelance Work */}
				</div>
			</div>
			{/* Freelance Work Wrap */}
		</section>
	);
};

export default FreelanceWork2;
