export const education = [
	{
		eduDate: '2019',
		eduTitle: "Institut Supérieur d'Ostéopathie Paris ",
		eduIns: 'ISOP',
		eduDesc: `Un cursus de 5 ans en ostéopathie où j'ai appris les techniques suivantes : ostéopathie structurelle, tissulaire, myotensive, fonctionnelle, crânienne et viscérale. Mon mémoire de fin d'étude portait sur l'influence d'un traitement ostéopathique sur les rachialgies chez les joueurs de jeux vidéo, ce qui m'a permis de traiter ce type de patients.`,
	},
	{
		eduDate: 'STAGE',
		eduTitle: 'EHPAD Le Patio',
		eduIns: 'Roissy en Brie',
		eduDesc:
			'Stage en ostéopathie auprès de personnes âgées dans un EHPAD, en utilisant des techniques adaptées pour maintenir leur mobilité et améliorer leur qualité de vie.',
	},
	{
		eduDate: 'STAGE',
		eduTitle: 'Pouponnière Paul Manchon',
		eduIns: 'Le Plessis Robinson',
		eduDesc: `Ici, j'ai eu la chance d'appliquer mes connaissances ostéopathiques au soin des nourrissons et des jeunes enfants. J'ai travaillé à traiter des problèmes communs de cette tranche d'âge, tels que les coliques, les problèmes de sommeil et les troubles de l'allaitement.`,
	},
	{
		eduDate: 'STAGE',
		eduTitle: 'Service des urgences',
		eduIns: 'Hopital Saint Louis Paris',
		eduDesc: `Ce stage m'a permis d'expérimenter un environnement à haut rythme et à haute pression, où j'ai aidé à fournir des soins d'urgence aux patients. J'ai acquis de l'expérience dans le traitement de diverses urgences médicales et traumatologiques, tout en apprenant à travailler en équipe avec des professionnels de la santé d'autres disciplines.`,
	},
	{
		eduDate: 'STAGE',
		eduTitle: 'Dissection à la faculté de médecine',
		eduIns: 'Paris Descartes',
		eduDesc: `Lors de ce stage unique, j'ai eu l'opportunité d'approfondir ma compréhension de l'anatomie humaine grâce à la dissection. Cette expérience m'a aidé à consolider ma connaissance des structures corporelles, ce qui est essentiel pour pratiquer efficacement l'ostéopathie. J'ai également eu l'occasion de travailler en étroite collaboration avec des étudiants en médecine et des professeurs experts en anatomie.`,
	},
];
