import PatternImg2 from '../../assets/images/patternImg2.jpg';
import PatternImg from '../../assets/images/patternImg3.jpg';
import { skill2 } from '../../data/skill2';
import { SectionTitle } from '../SectionTitles';

const Skill = (props) => {
	return (
		<section className="postsWrap py-[5rem] sm:py-[6rem] md:py-[7.5rem] relative w-full">
			<div
				className="fixedBg bg-top  bg-no-repeat opacity-20"
				style={{ backgroundImage: `url(${PatternImg})` }}
			></div>
			<div className="container sm:container md:container lg:container xl:max-w-[73.125rem] mx-auto">
				<SectionTitle
					title="Mon Expertise"
					titleInner="& Techniques"
				></SectionTitle>
				<div className="postsList grid gap-[1.875rem] lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 grid-cols-1 relative w-full">
					{skill2.map(
						(item, index) =>
							index < 7 && (
								<div className="postBox group relative w-full" key={index}>
									<div className="postImg overflow-hidden rounded-[10px] sm:rounded-[1.25rem] md:rounded-[1.875rem] w-full">
										<img
											className="w-full group-hover:scale-105"
											src={item.skillImg}
											alt={item.skillTitle}
										></img>
									</div>
									<div className="postInfo relative z-[1] pl-12 pr-5 md:px-10 py-5 sm:py-7 md:py-8 mt-[-4.6875rem] ml-16 sm:ml-[1.25rem] md:left-1/2 md:-translate-x-1/2 max-w-[80%] lg:max-w-[23.75rem] md:pl-[2.6875rem]">
										<div
											className="before:absolute before:inset-0 before:bg-white before:rounded-[10px] before:sm:rounded-[1.25rem] before:md:rounded-[1.875rem] before:opacity-70 before:z-[1] bg-blend-multiply absolute rounded-[10px] sm:rounded-[1.25rem] md:rounded-[1.875rem] inset-0 w-full bg-no-repeat bg-center bg-cover z-[-1] bg-gray"
											style={{ backgroundImage: `url(${PatternImg2})` }}
										></div>

										<h3 className="text-accent2 text-[1.25rem] font-Poppins font-semibold mt-2">
											{item.skillTitle}
										</h3>
										<i className="bg-[#d5daf2] h-[1px]  border-0 relative before:absolute before:bg-accent before:w-[3.125rem] before:h-[3px] before:z-[1] block before:mt-[-1px] mt-5 mb-4"></i>
										<p className="text-justify text-desc2"> {item.skillText}</p>
									</div>
									{/* Post Box */}
								</div>
							),
					)}
					{/* Posts List */}
				</div>
			</div>
		</section>
	);
};

export default Skill;
