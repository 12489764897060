import RouterLinks from './router/Routes';

function App() {
	return (
		<div className="fastoneApp">
			<RouterLinks></RouterLinks>
		</div>
	);
}

export default App;
