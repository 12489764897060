import { ReactComponent as EnvelopeIcon } from '../lib/icons/Envelope.svg';
import { ReactComponent as HomeIcon } from '../lib/icons/Home.svg';
import { ReactComponent as PhoneIcon } from '../lib/icons/Phone.svg';

export const conInfo2 = [
	{
		conIcon: <HomeIcon className="h-[2.4375rem] fill-accent"></HomeIcon>,
		conCap: '16 Rue Parmentier, 93220 Gagny',
	},
	{
		conIcon: <PhoneIcon className="h-[2.4375rem] fill-accent"></PhoneIcon>,
		conCap: '+33 6 26 38 90 47',
	},
	{
		conIcon: (
			<EnvelopeIcon className="h-[2.4375rem] fill-accent"></EnvelopeIcon>
		),
		conCap: 't.hacquemand.osteo@gmail.com',
	},
];

export const conInfo3 = [
	{
		conIcon: <PhoneIcon className="h-[2.4375rem] fill-accent"></PhoneIcon>,
		conCap: '+33 6 26 38 90 47',
		conHref: 'tel:+33 6 26 38 90 47',
	},
	{
		conIcon: (
			<EnvelopeIcon className="h-[2.4375rem] fill-accent"></EnvelopeIcon>
		),
		conCap: 't.hacquemand.osteo@gmail.com',
		conHref: 'mailto:t.hacquemand.osteo@gmail.com',
	},
	{
		conIcon: <HomeIcon className="h-[2.4375rem] fill-accent"></HomeIcon>,
		conCap: '16 Rue Parmentier, 93220 Gagny',
	},
];
