import React, { useLayoutEffect, useRef, useState } from 'react';

const MenuLinks2 = () => {
	const [activeSection, setActiveSection] = useState('acceuil');

	const refNav = useRef();
	useLayoutEffect(() => {
		const nav = document.getElementById('nav');
		let fixedTop = refNav.current.offsetTop;
		const navi = () => {
			if (window.pageYOffset > fixedTop) {
				nav.classList.add('text-accent');
				nav.classList.remove('text-white');
			}
		};
		window.addEventListener('scroll', navi);
	}, []);

	const scrollToAnchor = (event, anchorId) => {
		event.preventDefault();
		const element = document.getElementById(anchorId);
		if (element) {
			element.scrollIntoView({ behavior: 'smooth' });
			setActiveSection(anchorId);
		}
	};

	return (
		<nav ref={refNav} id="nav" className="relative hidden lg:block text-accent">
			<ul className="flex flex-col lg:flex-row gap-4 lg:gap-10 ">
				<li
					className={` relative group text-[1rem] lg:text-[1.125rem] font-Poppins font-semibold uppercase ${
						activeSection === 'accueil' ? 'bg-accent rounded-lg text-white' : ''
					}`}
				>
					<a
						className="hover:bg-accent hover:rounded-lg hover:text-white px-3  relative block"
						href="#accueil"
						onClick={(event) => scrollToAnchor(event, 'accueil')}
						title="Accueil - Meilleur Ostéopathe Gagny"
					>
						Accueil
					</a>
				</li>

				<li
					className={` relative group text-[1rem] lg:text-[1.125rem] font-Poppins font-semibold uppercase ${
						activeSection === 'ostéopathie'
							? 'bg-accent rounded-lg text-white'
							: ''
					}`}
				>
					<a
						className="hover:bg-accent rounded-lg hover:text-white px-3  relative block"
						href="#ostéopathie"
						onClick={(event) => scrollToAnchor(event, 'ostéopathie')}
						title="ostéopathie"
					>
						Ostéopathie
					</a>
				</li>
				<li
					className={` relative group text-[1rem] lg:text-[1.125rem] font-Poppins font-semibold uppercase ${
						activeSection === 'formation'
							? 'bg-accent rounded-lg text-white'
							: ''
					}`}
				>
					<a
						className="hover:bg-accent rounded-lg hover:text-white px-3  relative block"
						href="#formation"
						onClick={(event) => scrollToAnchor(event, 'formation')}
						title="Formations"
					>
						Formations
					</a>
				</li>

				<li
					className={` relative group text-[1rem] lg:text-[1.125rem] font-Poppins font-semibold uppercase ${
						activeSection === 'honoraires'
							? 'bg-accent rounded-lg text-white'
							: ''
					}`}
				>
					<a
						className="hover:bg-accent rounded-lg hover:text-white px-3  relative block"
						href="#honoraires"
						onClick={(event) => scrollToAnchor(event, 'honoraires')}
						title="Honoraires"
					>
						Honoraires
					</a>
				</li>
			</ul>
		</nav>
	);
};

export default MenuLinks2;
