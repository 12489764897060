import { conInfo3 } from '../../data/contact-info2';

const ContactInfo3 = () => {
	return (
		<div className="mt-4 conInfoList flex flex-col space-y-3 w-auto text-accent2 lg:w-[30rem]">
			{conInfo3.map(
				(item, index) =>
					index < 3 && (
						<a
							href={item.conHref}
							className="conInfoBox cursor-pointer hover:text-accent  border-[1px] border-dashed hover:border-accent border-accent flex gap-[1.0625rem] items-center w-full rounded-[10px] md:rounded-[.9375rem] lg:rounded-[1.25rem] lg:px-10 md:px-8 px-5 py-[1.75rem] transition-transform duration-500 ease-in-out transform hover:scale-110"
							key={index}
						>
							<span>{item.conIcon}</span>
							<div className="conInfoInner flex flex-col gap-1">
								<a className="  font-Poppins font-bold text-[1.25rem] uppercase">
									{item.conTitle}
								</a>
								<p className="text-[1rem] md:text-[1.125rem] font-NunitoSans font-semibold">
									{item.conCap}
								</p>
							</div>
							{/* Contact Info Box */}
						</a>
					),
			)}
			{/* Contact Info List */}
		</div>
	);
};

export default ContactInfo3;
