import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { BottomBar } from '../../components/BottomBar';
import { Header2 } from '../../components/Header';
import {
	Education2,
	FeaturedArea,
	FreelanceWork2,
	PriceTable,
	Services2,
	Skill,
} from '../../components/used';

const HomePage2 = () => {
	return (
		<Fragment>
			<Helmet>
				<title>Ostéopathe D.O Gagny - Timothé Hacquemand</title>
			</Helmet>
			<Header2></Header2>
			<FeaturedArea></FeaturedArea>
			<Services2></Services2>
			<Skill></Skill>
			<Education2></Education2>
			<FreelanceWork2></FreelanceWork2>
			<PriceTable></PriceTable>
			<BottomBar></BottomBar>
		</Fragment>
	);
};

export default HomePage2;
