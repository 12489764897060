import SkillImg1 from '../assets/images/resources/SkillImg1.png';
import SkillImg2 from '../assets/images/resources/SkillImg2.png';
import SkillImg3 from '../assets/images/resources/SkillImg3.png';
import SkillImg4 from '../assets/images/resources/SkillImg4.png';
import SkillImg5 from '../assets/images/resources/SkillImg5.png';
import SkillImg6 from '../assets/images/resources/SkillImg6.png';

export const skill2 = [
	{
		id: 1,
		skillImg: SkillImg1,
		skillTitle: 'Ostéopathie Structurelle',
		skillText: `Axée principalement sur le système musculo-squelettique, cette forme d'ostéopathie emploie des manipulations physiques pour rétablir l'équilibre du corps, soulager les tensions et améliorer la mobilité.`,
	},
	{
		id: 2,
		skillImg: SkillImg2,
		skillTitle: 'Ostéopathie Tissulaire',
		skillText: `Une approche douce qui se concentre sur la tension et les restrictions dans les tissus mous du corps, comme les muscles et les fascias. Elle utilise des techniques d'élongation, de compression et de glissement pour libérer ces tensions et favoriser la santé globale.`,
	},
	{
		id: 3,
		skillImg: SkillImg3,
		skillTitle: 'Ostéopathie Myotensive',
		skillText: `Avec un accent mis sur le système musculaire, cette approche implique des étirements passifs, des pressions et des contractions musculaires pour relâcher les tensions et optimiser la fonction musculaire.`,
	},
	{
		id: 4,
		skillImg: SkillImg4,
		skillTitle: 'Ostéopathie Fonctionnelle',
		skillText: `Cette branche de l'ostéopathie se base sur les mouvements naturels du corps pour favoriser l'équilibre et la guérison, se concentrant principalement sur les articulations et les muscles.`,
	},
	{
		id: 5,
		skillImg: SkillImg5,
		skillTitle: 'Ostéopathie Crânienne',
		skillText: `Cette pratique met l'accent sur les mouvements subtils du crâne et du système nerveux central. Les techniques douces employées visent à libérer les tensions et favoriser la circulation du liquide céphalorachidien, contribuant ainsi à un effet bénéfique sur l'ensemble du corps.`,
	},
	{
		id: 6,
		skillImg: SkillImg6,
		skillTitle: 'Ostéopathie Viscérale',
		skillText: `Cette forme de l'ostéopathie cible les organes internes. Elle emploie des techniques manuelles pour améliorer la mobilité des organes, stimuler la circulation sanguine et lymphatique, et aider à restaurer l'équilibre global du corps.`,
	},
];
