export const priceTable = [
	{
		pacBox: '',
		pacTitle: 'Enfant',
		pacTitleColor: 'text-[#213e6e]',
		pacCurrency: '€',
		pacCurrencyColor: 'text-accent2',
		pacPrice: '55',
		pacPriceColor: 'text-accent2',
		pacSubTitle: 'Monthly Package',
		pacSubColor: 'text-accent',
		pacBoxHead: 'before:bg-white bg-white',
		pacBoxInner:
			'before:bg-white bg-white px-10 pb-[2.8125rem] border-x-[10px] border-b-[10px] border-white py-[2rem] md:py-[2.1875rem] xl:px-[3.75rem]',
		pacLinkBox: 'bg-accent text-white hover:bg-accent2 hover:text-white',
	},
	{
		pacBox: 'lg:mt-[1.875rem]',
		pacTitle: 'Adult',
		pacTitleColor: 'text-white',
		pacCurrency: '€',
		pacCurrencyColor: 'text-white',
		pacPrice: '60',
		pacPriceColor: 'text-white',
		pacSubTitle: 'Monthly Package',
		pacSubColor: 'text-white',
		pacBoxHead: 'before:bg-accent bg-accent',
		pacBoxInner:
			'before:bg-accent bg-accent px-10 pb-[3.4375rem] py-[2rem] px-[2.5rem] md:py-[2.1875rem] xl:px-[4rem]',

		pacLinkBox: 'bg-white text-accent hover:bg-accent2 hover:text-white',
	},
	{
		pacBox: '',
		pacTitle: 'Domicile',
		pacTitleColor: 'text-[#213e6e]',
		pacCurrency: '€',
		pacCurrencyColor: 'text-accent2',
		pacPrice: '80',
		pacPriceColor: 'text-accent2',
		pacSubTitle: 'Monthly Package',
		pacSubColor: 'text-accent',
		pacBoxHead: 'before:bg-white bg-white',
		pacBoxInner:
			'before:bg-white bg-white px-10 pb-[2.8125rem] border-x-[10px] border-b-[10px] border-white py-[2rem] md:py-[2.1875rem] xl:px-[3.75rem]',
		pacList: [
			{
				pacListItem: 'Web Development',
				pacListItemColor: 'text-desc2',
			},
			{
				pacListItem: 'Digital Marketing',
				pacListItemColor: 'text-desc2',
			},
			{
				pacListItem: 'Graphic Design',
				pacListItemColor: 'text-desc2',
			},
			{
				pacListItem: '24/Support',
				pacListItemColor: 'text-desc2',
			},
		],
		pacLink: '#',
		pacLinkBox: 'bg-accent text-white hover:bg-accent2 hover:text-white',
	},
];
