import React, { useLayoutEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { ResponsiveHeader } from '../ResponsiveHeader';
import MenuLinks2 from './MenuLinks2';

const Header2 = () => {
	const refHeader = useRef();
	useLayoutEffect(() => {
		const header = document.getElementById('header2');
		let fixedTop = refHeader.current.offsetTop;
		const stickyHeader = () => {
			if (window.pageYOffset > fixedTop) {
				header.classList.add(
					'stickyHeader',
					'bg-white/80',
					'drop-shadow-2xl',
					'py-3',
					'backdrop-blur-sm',
				);
			} else {
				header.classList.remove(
					'stickyHeader',
					'bg-white/80',
					'drop-shadow-2xl',
					'py-3',
					'backdrop-blur-sm',
				);
			}
		};
		window.addEventListener('scroll', stickyHeader);
	}, []);

	return (
		<React.Fragment>
			<header
				className="fixed transition-all ease-in duration-300 top-0 left-0 w-full py-6 z-[9999] hidden lg:block"
				ref={refHeader}
				id="header2"
			>
				<div className="logoMenu w-full">
					<div className="container sm:container md:container lg:container xl:container 2xl:max-w-[100rem] mx-auto">
						<div className="logoMenuInner flex items-center justify-between relative w-full">
							<div className="logo inline-block max-w-[50%]"></div>
							<MenuLinks2></MenuLinks2>
							<div className="menuRight hidden lg:flex items-center gap-8 lg:gap-12">
								<Link
									className="bg-white border-2 border-current text-accent text-[1rem] font-Poppins font-bold uppercase rounded-[5px] md:rounded-[10px] md:px-6 lg:px-10 py-2 hover:bg-accent hover:border-accent hover:text-white text-center inline-block"
									to="https://www.doctolib.fr/osteopathe/gagny/timothe-hacquemand"
									title="Prendre rendez-vous"
								>
									Prendre rendez-vous
								</Link>
							</div>
							{/* Logo */}
						</div>
						{/* Logo Menu Inner */}
					</div>
					{/* Logo Menu */}
				</div>
				{/* Header */}
			</header>
			<ResponsiveHeader></ResponsiveHeader>
		</React.Fragment>
	);
};

export default Header2;
